<template>
  <a-card>
    <a-page-header
        :title='"编辑标签"'
    />
    <a-form-model
        ref="form"
        :model="form"
        v-bind="layout"
    >
      <div class="title">基础信息</div>
      <a-form-model-item label="标签分类">
        {{ form.labelSort || "-" }}
      </a-form-model-item>
      <a-form-model-item label="标签名称">
        {{ form.labelName || "-" }}
      </a-form-model-item>

      <div class="title">标签规则</div>
      <a-form-model-item label="标签值">
        <div style="padding: 9px 0; line-height: 1.5;">
          每个文本框视为一个标签项，彼此为互斥关系；每个标签项内，可批量录入标签值，彼此为并列关系。
        </div>
        <div class="tag-wrap">
          <div class="wrap" v-for="(item, index) in form.items" :key="index">
            <div class="box">
              <a-tag color="blue" closable @close.prevent="delSingleTag(item, n)" @click="onClickTag(item, m)"
                     v-for="(m, n) in item.label" :key="n">{{ m.labelName }}
              </a-tag>
              <a-button @click="showInputBox(item)" v-if="item.label.length < 50">
                <a-icon :type="item.showInput ? 'close' : 'plus'"/>
              </a-button>
            </div>
            <div class="action-box">
              <a-button type="link" class="btn" icon="close" @click="delTagBox(index)">删除</a-button>
            </div>
            <div class="input-box" v-show="item.showInput">
              <div class="input">
                <a-input v-model="item.input" placeholder="请输入标签值" allow-clear :maxLength="50"
                         @change="onChangeInput(item)"/>
                <span class="num">{{ item.inputLength || 0 }}/50</span>
              </div>
              <a-button type="primary" @click="editSingleTag(item)" :disabled="!item.input" v-if="labelId">{{
                  btnText
                }}
              </a-button>
              <a-button type="primary" @click="addSingleTag(item)" :disabled="!item.input" v-else>{{
                  btnText
                }}
              </a-button>
            </div>
          </div>
        </div>
        <a-button type="dashed" style="width: 100%" @click="addTagBox">
          <a-icon type="plus"/>
          添加标签项
        </a-button>
      </a-form-model-item>
      <a-form-model-item label="更新方式">
        <a-radio-group v-model="form.useType">
          <a-radio :value="1">
            手动
          </a-radio>
          <!--<a-radio :value="2">-->
          <!--  自动-->
          <!--</a-radio>-->
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="生效周期">
        <a-radio-group v-model="form.effectTimeType">
          <a-radio :value="1">
            按周
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label=" " :colon="false">
        <a-space>
          <base-button
              :title="'取消'"
              @onClickBtn="handleBack"
          ></base-button>
          <base-button
              :type="'primary'"
              :title="'保存'"
              :loading="isSubmit"
              :disabled="isSubmit"
              @onClickBtn="handleSubmit"
          ></base-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-card>
</template>

<script>
import Api from "@/api/tagManage";
import { flattenDeep } from "lodash-es";

export default {
  data() {
    return {
      layout: {
        labelCol: {span: 6},
        wrapperCol: {span: 11}
      },
      form: {},
      totalTagList: [],
      isSubmit: false,
      id: this.$route.query.id,
      btnText: "创建",
      labelId: null,
      labelList: []
    };
  },
  mounted() {
    this.getLabelData();
    this.formatList();
  },
  methods: {
    handleBack() {
      let that = this;
      this.$confirm({
        content: h => <div><span style="color: red; display: block;">表单数据还未保存</span>离开数据将会丢失，是否确认离开？
        </div>,
        okText: "确定",
        cancelText: "取消",
        onOk() {
          that.$router.push({
            path: "tagList"
          });
        }
      });
    },
    handleSubmit() {
      let items = this.form.items.map(item => {
        delete item.input;
        delete item.inputLength;
        delete item.showInput;
        return item;
      });
      let params = {
        id: this.form.id,
        useType: this.form.useType,
        effectTimeType: this.form.effectTimeType,
        items: items,
      };
      Api.saveLabel(params).then((res) => {
        if(res.code == 200) {
          this.$message.success("保存成功");
          this.$router.push({
            path: "tagList"
          });
        } else {
          console.error(`获取数据失败${ res }`);
        }
      });
    },
    onClickTag(item, m) {
      this.btnText = "确定";
      this.labelId = m.id;
      this.$set(item, "input", m.labelName);
      this.$set(item, "showInput", true);
      if(item.showInput) {
        this.onChangeInput(item);
      }
    },
    onChangeInput(item) {
      item.inputLength = item.input.length;
    },
    addSingleTag(item) {
      const list = flattenDeep(this.form.items.map(v => v.label));
      if(item.input && !list.some(m => m.labelName == item.input)) {
        item.label.push({
          id: Math.floor(Math.random() * (0 - 20 + 1)) + 0,
          labelName: item.input
        });
        this.$set(item, "input", "");
        this.$set(item, "showInput", false);
      } else {
        this.$message.error("该标签值已存在");
      }

    },
    editSingleTag(item) {
      if(this.labelId) {
        item.label.map(k => {
          if(k.id == this.labelId) {
            this.$set(k, "labelName", item.input);
          }
          return k;
        });
        this.$set(item, "showInput", false);
        this.formatList();
      }
    },
    delSingleTag(item, n) {
      this.$confirm({
        title: "提示",
        content: "删除该标签值后不可恢复，确定删除？",
        onOk: () => {
          item.label.splice(n, 1);
          this.formatList();
        },
      });
    },
    addTagBox() {
      if(this.form.items.length < 10) {
        this.form.items.push(
            {label: [], input: "", showInput: false, labelItemCode: ""}
        );
      } else {
        this.$message.error("最多添加10个标签项");
      }
    },
    delTagBox(index) {
      this.$confirm({
        title: "提示",
        content: "删除标签项会将该标签项内的所有标签值全部删除，且不可恢复，确定删除？",
        onOk: () => {
          this.form.items.splice(index, 1);
          this.formatList();
        },
      });
    },
    showInputBox(item) {
      this.labelId = null;
      this.btnText = "创建";
      item.showInput = !item.showInput;
      item.input = "";
      this.onChangeInput(item);
    },
    formatList() {
      let totalTagList = [];
      this.form.items?.map(m => {
        m.label.map(n => {
          totalTagList.push(n.labelName);
        });
      });
      this.totalTagList = totalTagList;
    },
    getLabelData() {
      Api.getLabelData({id: this.id}).then((res) => {
        if(res.code == 200) {
          this.form = res.data;
          let items = res.data.items?.map(item => {
            return {
              label: item.label,
              labelItemCode: item.labelItemCode,
              input: "",
              showInput: false,
            };
          });
          this.$set(this.form, "items", items);
          this.form.items.forEach(a => {
            a.label.forEach(k => {
              this.labelList.push(k);
            });
          });
          if(!this.form.items.length) {
            this.form.items.push(
                {label: [], input: "", showInput: false, labelItemCode: ""},
                {label: [], input: "", showInput: false, labelItemCode: ""},
                {label: [], input: "", showInput: false, labelItemCode: ""},
            );
          }
        } else {
          console.error(`获取数据失败${ res }`);
        }
      });
    }
  }
};
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
  padding-left: 0;
  padding-right: 0;
}

.title {
  padding: 20px 0 10px;
  margin-bottom: 20px;
  font-weight: bold;
  border-bottom: 1px solid #eee;
}

/deep/ .ant-form-item {
  margin-bottom: 10px;
}

.tag-wrap {
  border-left: 2px solid rgba(128, 155, 206, 0.5);
  padding-left: 5px;

  .wrap {
    position: relative;
    padding: 10px 10px;

    &:hover {
      background-color: rgba(128, 155, 206, 0.15);

      .action-box {
        display: flex;
        align-items: center;
      }
    }
  }

  .box {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px 0 10px;
    border: 1px solid #eee;
    border-radius: 4px;
    background-color: #fff;
    overflow: hidden;

    /deep/ .ant-tag {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      min-height: 32px;
      white-space: normal;
    }

    /deep/ .ant-btn {
      margin-bottom: 10px;
    }
  }

  .action-box {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    bottom: 0;
    background-color: rgba(128, 155, 206, 0.15);

    .btn {
      color: rgba(0, 0, 0, 0.65);

      &:hover {
        transform: scale(1.1);
        color: #1890ff;
      }
    }
  }

  .input-box {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .input {
      flex: 1;
      position: relative;

      /deep/ .ant-input {
        padding-right: 75px;
        width: 100%;
      }

      .num {
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
      }
    }

    .icon {
      cursor: pointer;
    }
  }
}
</style>
